// Section: Variables
$shadow-normal: 0 1px 3px 0 rgba(207, 207, 207, 0.1),
  0 1px 2px 0 rgba(207, 207, 207, 0.06);
$shadow-medium: 0 4px 6px -1px rgba(114, 114, 114, 0.1),
  0 2px 4px -1px rgba(114, 114, 114, 0.06);
$shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

// Section: Header
.header {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  // padding: .4rem 0;
  height: 80px;
  margin: 0 auto;
  z-index: 999;
  border: none;
  outline: none;
  background-color: $c-background;
  // border-top: 4px solid $c-fifth;
  display: flex;
  align-items: center;

  @media (max-height: 768px) {
    height: 70px;
  }

  .brand {
    img {
      height: 55px;
      @media (max-width: 800px) {
        height: 50px;
      }

      @media (max-height: 768px) {
        height: 50px;
      }

      @media (max-width: 500px) {
        height: 50px !important;
      }
      @media (max-width: 400px) {
        height: 34px !important;
      }
    }
  }

  ul {
    margin-bottom: 0;
    width: 100%;
    padding-left: 0;
  }
  .menu-section {
    margin-bottom: 0;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1em;
    width: 100%;
    padding-left: 0;
    @media (max-width: 1199px) {
      display: inline-block;
      padding-left: 0;
    }
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  &-item-left {
    display: flex;
    flex: 0 0 20%;
  }
  &-item-center {
    display: flex;
    flex: 0 0 60%;
    justify-content: center;
  }
  &-item-right {
    display: flex;
    flex: 0 0 18%;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }

    @media (max-width: 500px) {
      .button {
        height: 40px;
        a {
          padding: 0 8px;
        }
      }
    }

    .menu-icon {
      font-size: 1rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      width: 40px;
      height: 40px;
      background-color: rgba($c-main-dark, $alpha: 0.1);
      border-radius: 6px;
      -ms-flex-align: center;
      align-items: center;
      // color: $c-second;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      gap: 0.5rem;
      margin-left: 0.3rem;
      border: none;
      text-decoration: none;
      outline: none;
      color: $c-main-dark;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  }
  a,
  button {
    font-family: "Roboto", sans-serif;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    border: none;
    outline: none;
    background: 0 0;
    text-decoration: none;
  }

  .menu {
    width: 100%;
  }
  .menu > ul > li {
    // display: flex;
    // line-height: 4.125rem;
    // text-align: center;
    // border-bottom: 2px solid transparent;
    display: inline-block;
    margin-left: 0;
    line-height: 3.125rem;
    text-align: center;
    border-bottom: 2px solid transparent;
    @media (max-width: 1199px) {
      text-align: left;
    }

    > a {
      position: relative;
      font-family: inherit;
      font-size: 1rem;
      font-weight: 500;
      width: 100%;
      font-weight: 500;
      line-height: 4.525rem;
      border: none;
      text-decoration: none !important;
      outline: none !important;
      color: $c-dark;
      // text-transform: uppercase;
      text-rendering: optimizeLegibility;
      transition: color 0.3s ease-in-out;

      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 100%;
        position: absolute;
        bottom: -8px;
        height: 100%;
        left: 0;
        right: 0;
        // position: absolute;
        // width: 100px;

        // height: 40px;
        // background-color: $c-main;
        -webkit-transition: opacity 0.3s ease, width 0.3s ease;
        -moz-transition: opacity 0.3s ease, width 0.3s ease;
        -o-transition: opacity 0.3s ease, width 0.3s ease;
        transition: opacity 0.3s ease, width 0.3s ease;
        -ms-transition: opacity 0.3s ease, width 0.3s ease;
        // border-radius: 10px;

        @media (max-width: 1199px) {
          width: 6px;
          height: 0;
          margin: 0 0;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &.active,
      &:hover {
        color: $c-second;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1199px) {
          justify-content: flex-start;
        }
        &:after {
          width: 100%;
          content: "";
          display: block;
          margin: 0 auto;
          width: 40px;
          position: absolute;
          bottom: 0px;
          height: 4px;
          border-radius: 4px;
          background-color: $c-second;
          left: 0;
          right: 0;

          @media (max-width: 1199px) {
            width: 6px;
            left: unset;
            height: 100%;
          }
        }
      }
    }
    .menu-subs {
      position: absolute;
      width: 100%;
      height: auto;
      margin-top: 1.75rem;
      padding: 1rem 2rem;
      border: none;
      outline: none;
      z-index: 500;
      opacity: 0;
      visibility: hidden;
      border-radius: 0.25rem;
      border-top: 3px solid $c-second;
      background: $c-white;
      box-shadow: $shadow-medium;
      transition: all 0.5s ease-in-out;
      > ul > li {
        line-height: 1;
        > a {
          display: inline-block;
          font-family: inherit;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.25;
          padding: 0.75rem 0;
          border: none;
          outline: none;
          color: $c-main;
          text-transform: capitalize;
          text-rendering: optimizeLegibility;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    .menu-subs {
      &.menu-mega {
        left: 50%;
        transform: translateX(-50%);
        ul  {
          padding-left: 0;
        }

        .bg--green {
          background-color: rgba($c-third, 0.03);
        }

        > .list-item > ul > li {
          display: block;
          line-height: 1;
          background-color: $c-light;
          padding: 4px 8px;
          margin-bottom: 4px;
          > a {
            display: inline-block;
            font-family: inherit;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.25;
            padding: 0.5rem 0;
            border: none;
            outline: none;
            color: $c-dark;
            text-transform: capitalize;
            text-rendering: optimizeLegibility;
            transition: color 0.3s ease-in-out;
          }
        }
      }
      &.menu-column-4 {
        display: flex;
        flex-wrap: wrap;
        // max-width: 68rem;
        // max-width: 74rem;
        gap: 16px;
        max-width: 80%;
        width: 100%;
        padding: 1.25rem 1rem;
        justify-content: center;

        .item {

            @media (max-width: 1199px) {
                margin: 16px 0;
            }
          img {
            height: 300px;
            border-radius: 16px;
          }
          .head {
            h3 {
              font-size: clamp(16px, 3vw, 20px);
            }
          }
          .short {
            p {
              text-align: center;
              line-height: 24px;
            }
          }
        }

        > .list-item {
          // flex: 0 0 25%;
          // padding: 0 1rem;
          // border-right: 1px solid #f7f4e6;
          // margin-bottom: 8px;
          flex: 0 0 32%;
          // padding: 0 1rem;
          // margin: 0px 6px;
          // border-right: none;
          // margin-bottom: 8px;
          // background: wheat;
          // &:last-child,&:nth-child(4) {
          //     border-right: none
          // }

          &:hover .title img {
            transform: scale(1.2) rotate(3deg) translateY(-4px) translateX(-2px);
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease;
            transition: all 1s ease;
            -ms-transition: all 1s ease;
          }
          .title {
            font-family: inherit;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1;
            padding: 0.75rem 0;
            border: none;
            outline: none;
            color: $c-dark;
            text-transform: uppercase;
            text-rendering: optimizeLegibility;

            img {
              width: 60px;
              object-fit: cover;
              border-radius: 8px;
              height: 60px;
              margin-bottom: 8px;
              transition: all 0.3s ease-in-out;
              border: 1px solid $c-third;
              @media (max-width: 1200px) {
                width: 32px;
                object-fit: cover;
                height: 32px;
                border-radius: 4px;
                margin-bottom: 0;
              }
            }

            span {
              text-align: center;
            }
          }
          &.text-center .title {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgba($c-third, 0.2);
            border-radius: 8px;
            border: 1px solid $c-third;

            @media (max-width: 1200px) {
              flex-direction: row;
              justify-content: left;
              padding: 1rem;
            }
          }
          img.responsive {
            max-width: 100%;
            width: 100%;
            height: auto;
            margin-top: 0.75rem;
            object-fit: cover;
            vertical-align: middle;
          }
        }
      }
      &.menu-mega > .list-item > ul > li > a:hover,
      > ul > li > a:hover {
        color: $c-second;
      }
    }
  }
  &-item-right a:hover,
  .menu > ul > li:hover > a {
    color: $c-second;
  }
}

// Section: Media Query
@media only screen and (min-width: 1200px) {
  .header .menu > ul > li.menu-item-has-children:hover .menu-subs {
    margin-top: 0.5rem;
    opacity: 1;
    visibility: visible;
  }
}

.menu-mobile-header,
.menu-mobile-trigger {
  display: none;
}

// Section: Media Query
@media only screen and (max-width: 1199px) {
  .header {
    &-item-center {
      flex: 0 0 100%;
      order: 3;
    }
    &-item-left,
    &-item-right {
      flex: 0 0 auto;
    }
  }
  .wrapper {
    justify-content: space-between;
  }
  .header {
    .menu-mobile-trigger {
        position: relative;
        display: block;
        cursor: pointer;
        // width: 1.75rem;
        // height: 1rem;
        border: none;
        outline: none;
        margin-left: .5rem;
        background: $c-background;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0deg);
        transition: 0.35s ease-in-out;

        svg {
            height: 40px;
            width: 40px;
        }
        span {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            border: none;
            outline: none;
            opacity: 1;
            border-radius: 0.25rem;
            background: $c-main-dark;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2),
            &:nth-child(3) {
                top: 0.5rem;
            }
            &:nth-child(4) {
                top: 1rem;
            }
        }
    }
    &-item-right {
      align-items: center;
    }
    .menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 22rem;
      height: 100%;
      z-index: 1099;
      overflow: hidden;
      background: $c-white;
      transform: translate(-100%);
      transition: all 0.5s ease-in-out;
      &.active {
        transform: translate(0%);
      }
      > ul > li {
        display: block;
        line-height: 1;
        margin: 0;
        line-height: 1;

        > a {
          display: block;
          line-height: 4.125rem;
          height: 4.125rem;
          padding: 0 4.125rem 0 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          i.ion {
            position: absolute;
            font-size: 1.25rem;
            line-height: 4.125rem;
            top: 0;
            right: 0;
            width: 4.125rem;
            height: 4.125rem;
            text-align: center;
            transform: rotate(-90deg);
          }
        }
      }
      .menu-mobile-header {
        position: relative;
        position: sticky;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;
        height: 4.125rem;
        z-index: 501;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: $c-white;

        img {
          height: 60px;
        }
        .menu-mobile-arrow {
          display: none;
          font-size: 1.25rem;
          line-height: 3.125rem;
          width: 3.125rem;
          height: 3.125rem;
          cursor: pointer;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          color: $c-second;
          text-align: center;
        }
        &.active .menu-mobile-arrow {
          display: block;
        }
        .menu-mobile-title {
          font-family: inherit;
          font-size: 1rem;
          font-weight: 500;
          line-height: inherit;
          color: $c-second;
          text-transform: capitalize;
          text-rendering: optimizeLegibility;
        }
        .menu-mobile-close {
          font-size: 2.25rem;
          line-height: 3.125rem;
          cursor: pointer;
          width: 3.125rem;
          height: 3.125rem;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          color: $c-second;
          text-align: center;
        }
      }
      .menu-section {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }
      > ul > li .menu-subs {
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        max-width: none;
        min-width: auto;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0.2rem;
        padding-top: 4rem;
        opacity: 1;
        overflow-y: auto;
        visibility: visible;
        transform: translateX(0%);
        box-shadow: none;
        &.menu-mega {
          position: absolute;
          display: none;
          top: 0;
          left: 0;
          max-width: none;
          min-width: auto;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 1rem;
          padding-top: 4rem;
          opacity: 1;
          overflow-y: auto;
          visibility: visible;
          transform: translateX(0%);
          box-shadow: none;
        }
        &.active {
          display: block;
        }
        &.menu-column-4 > .list-item {
          flex: 0 0 100%;
          padding: 0rem;
          border-radius: 8px;
          img.responsive {
            margin-top: 0;
          }
          &.text-center {
            .title {
              margin: 0.5rem 0;
              padding: 0.6rem;
              text-align: left;
              background-color: rgba($c-main, 0.03);
              img {
                margin-right: 8px;
              }
            }
            &:last-child .title {
              margin-bottom: 0rem;
            }
          }
        }

        > ul > li > a {
          display: block;
        }
        &.menu-mega > .list-item > ul {
          > li > a {
            display: block;
          }
          margin-bottom: 1rem;
        }
      }
    }
  }
  .overlay--menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    opacity: 0;
    visibility: hidden;
    background: rgba($c-main-dark, 0.55);
    transition: all 0.5s ease-in-out;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.mobile--bottom {
  display: none !important;
  @media (max-width: 800px) {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  &--lang {
    img {
      width: 40px;
    }
  }
}

.sticky {
  background-color: $c-white !important;
  //   box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  box-shadow: $shadow-normal;
  position: sticky;
  top: 0;
  z-index: 9999;
}
