.products--area {

    .grid {
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        // grid-gap: 16px;

        // @media (max-width: 1199px) {
        //     grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
        // }

        margin-top: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 16px;
    }

    .prod--web {
        @media (max-width: 991px) {
            display: none;
        }
    }

    .prod--mobile {
        display: none;

        @media (max-width: 991px) {
            display: block;
            margin: 32px 0 0;

            .swiper-wrapper {
                margin-bottom: 32px;
            }

            .swiper-pagination {
                bottom: 0px;

                .swiper-pagination-bullet {
                    width: 24px;
                    height: 6px;
                    background-color: $c-main;
                    border-radius: 4px;
                }
            }

        }
    }

    &--item {
        min-width: 18%;
        width: 19%;
        position: relative;
        background-color: $c-white;
        border-radius: 8px;
        text-align: center;
        padding: 24px;
        box-shadow: rgba(176, 183, 189, 0.2) 0px 0px 16px;
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        -webkit-transition: border 1s ease;
        -moz-transition: border 1s ease;
        -o-transition: border 1s ease;
        transition: border 1s ease;
        -ms-transition: border 1s ease;

        @media (max-width: 1299px) {
            width: 32%;
        }

        @media (max-width: 1199px) {
            width: 32%;
        }

        @media (max-width: 991px) {
            width: 100%;
            margin: 10px 2px;
        }

        // @media (max-width: 580px) {
        //     padding: 16px;
        // }
        // @media (max-width: 500px) {
        //     width: 100%;
        // }

        &--content {
            transition: margin .5s ease-in;

            img {
                height: 60px;
                margin-bottom: 8px;
            }

            h3 {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 8px;
            }

            p {
                font-size: 12px;
                color: $c-gray-9c;
                line-height: 18px;
                margin-bottom: 0;
            }

        }

        &--action {
            // display: none;
            visibility: hidden;
            opacity: 0;
            // transition: all 1s ease;
            position: absolute;
            bottom: -200px;
            overflow: hidden;
            left: 0;
            right: 0;
            margin: 0 auto;
            transition: visibility 0s, opacity 0.5s linear;


            a {
                height: 24px;
                width: 24px;
                border-radius: 50px;
                color: $c-second;
                font-size: 20px;
            }

        }


        &:hover &--action {
            // display: inline-block;
            visibility: visible;
            opacity: 1;
            position: static;
            // margin-top: 24px;
        }

        &:hover &--content {
            margin-bottom: 16px;
        }

        &:hover {
            img {
                transform: scale(1.3) rotate(10deg);
                -webkit-transition: transform 1s ease;
                -moz-transition: transform 1s ease;
                -o-transition: transform 1s ease;
                transition: transform 1s ease;
                -ms-transition: transform 1s ease;
            }
        }

    }

    .one {
        &:hover {
            border-bottom: 3px solid $c-second;
            -webkit-transition: border 1s ease;
            -moz-transition: border 1s ease;
            -o-transition: border 1s ease;
            transition: border 1s ease;
            -ms-transition: border 1s ease;

        }


    }

    .two {
        &:hover {
            border-bottom: 3px solid $c-third;
            -webkit-transition: border 1s ease;
            -moz-transition: border 1s ease;
            -o-transition: border 1s ease;
            transition: border 1s ease;
            -ms-transition: border 1s ease;
        }
    }

    .three {
        &:hover {
            border-bottom: 3px solid $c-main;
        }
    }

    .four {
        &:hover {
            border-bottom: 3px solid $c-forth;
        }
    }

    .five {
        &:hover {
            border-bottom: 3px solid $c-fifth;
        }
    }
}





.section--bg {
    background-color: #fefaf5;
    position: relative;

    &:after {
        content: '';
        background: url('/assets/images/clip-top.jpg');
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        background-repeat: repeat;
        width: 100%;
        height: 65px;
        background-size: cover;
        background-position: center;
    }

    &:before {
        content: '';
        background: url('/assets/images/clip-top.jpg');
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        position: absolute;
        transform: rotate(180deg);
        background-repeat: repeat;
        width: 100%;
        height: 65px;
        background-size: cover;
        background-position: center;
    }
}

.auto--area--garanty {
    padding-bottom: 40px !important;

    .item {
        padding: 24px;
        border-radius: 16px;
        border: 2px solid rgba($c-gray, .2);
        background-color: $c-white;
        margin-bottom: 16px;

        .head {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .image {
                height: 60px;
                width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 16px;

                img {
                    height: 50px;
                }
            }

            h4 {
                font-size: 16px;
                font-weight: 500;
                color: $c-dark;
            }
        }

        p {
            color: $c-gray;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;

            margin-bottom: 0;
        }
    }
}



.cta {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    h3 {
        font-size: clamp(18px, 3vw, 24px);
        color: $c-second;
        font-weight: 500;
        margin-bottom: 32px;
        font-style: italic;
    }
}

.cta--third {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 0;

    h3 {
        font-size: clamp(18px, 3vw, 24px);
        color: $c-third;
        font-weight: 500;
        margin-bottom: 32px;
        font-style: italic;
    }
}


// CARDS

// S1
.card--s--one {
    position: relative;
    background-color: $c-white;
    border-radius: 8px;
    text-align: center;
    padding: 24px;
    box-shadow: rgba(176, 183, 189, 0.2) 0px 0px 16px;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    -webkit-transition: border 1s ease;
    -moz-transition: border 1s ease;
    -o-transition: border 1s ease;
    transition: border 1s ease;
    -ms-transition: border 1s ease;

    .image {
        margin-bottom: 16px;
        transition: all .5s ease-in;

    }

    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    p {
        font-size: 12px;
        color: $c-gray-9c;
        line-height: 18px;
        margin-bottom: 0;
    }


    &:hover {
        .image {
            transform: scale(1.3) rotate(10deg);
            -webkit-transition: transform 1s ease;
            -moz-transition: transform 1s ease;
            -o-transition: transform 1s ease;
            transition: transform 1s ease;
            -ms-transition: transform 1s ease;
        }
    }

}

.card--border--main {
    border-bottom: 2.5px solid $c-main;
   
}
.card--border--second {
    border-bottom: 2.5px solid $c-second;
}
.card--border--third {
    border-bottom: 2.5px solid $c-third;
}


// S2 
.guarantee--row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 16px;
    margin-bottom: 16px;

    @media (max-width: 1199px) {
        grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    }

    .item {
        border: .8px solid $c-third;
        padding: 16px;
        background-color: $c-white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 8px;

        img {
            height: 50px;
            margin-bottom: 10px;
        }

        h4 {
            font-size: clamp(12px, 3vw, 16px);
            color: $c-dark;
            margin-bottom: 0;
            font-weight: 400;
        }

        &:hover {
            background-color: rgba($c-third, .1);
        }
    }
}



// Stepper 
.steper--wrapper {

    .step {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 16px;

        .arrow {
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .number {
                background-color: rgba($c-main, .1);
                border-radius: 2px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
                color: $c-main;
                font-weight: 500;
                font-size: 16px;
                width: 45px;
                height: 45px;
            }
        }

        .content {
            padding-left: 16px;
            h5 {
                color: $c-main;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 8px;
            }

            p{
                margin-bottom: 0;
                color: $c-gray-9c;

            }
        }
    } 
}


// List
.ul--simple {
    list-style: none;
    margin-top: 16px;

    li {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
        h6 {
            color: $c-main;
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 4px;
        }

        p {
            color: $c-gray;
            font-size: 14px;
            font-weight: 300;
            margin-bottom: 0;
        }
    }
}



// Health Page

.health--area {
    padding-bottom: 40px;
    padding-top: 20px;

    @media (max-width: 991px) {
        padding-top: 0;
    }
    .title {
        font-size: clamp(16px, 3vw, 24px);
        font-weight: 600;
        color: $c-main-dark;
        line-height: 32px;


      @media (max-width: 991px) {
        text-align: center;
      }
    }
}