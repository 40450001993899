.pub--area {
    &--item {
        // background-color: rgba($c-main, .2);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 991px) {
            flex-wrap: wrap;
            justify-content: center;
        }

        .content {
            // width: 70%;
            padding: 16px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            @media (max-width: 991px) {
                align-items: center;
                text-align: center;
            }

            img {
                margin-bottom: 16px;
            }

            h2 {
                padding: 16px 0;
                color: $c-main;
                font-weight: 600;
            }

            p {
                color: $c-gray-76;
            }

            a {
                color: $c-gray-76;
                margin-top: 16px;
                font-size: 14px;
                font-weight: 300;

            }

            
        }

        .image {
            // width: 30%;
            padding: 16px;
            img {
                height: 260px;
                width: 100%;
                border-radius: 16px;
                @media (max-width: 991px) {
                    height: 200px;
                    padding-bottom: 16px;
                }
            }
        }
        
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            width: 24px;
            height: 8px;
            background-color: $c-main;
            border-radius: 4px;
        }
    }
    
}