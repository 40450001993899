

.card__features {
  
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;

  @media (max-width:1199px) {
    grid-template-columns: repeat(auto-fit, minmax(46%, 1fr));
  }

  @media (max-width:600px) {
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  }

  &--item {
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0 0px 10px rgba(247, 247, 247, 0.8);
    border-radius: 8px;
    display: flex;
    background-color: $c-white;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160px;
    padding: 16px;

    &--front {
      text-align: center;
  
      &--icon {
        // height: auto;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        img,
        svg {
          height: 90px;
          @media (max-width: 1191px) {
            height: 70px;
          }
        }
      }
  
      h4 {
        color: $c-main;
        --minFontSize: 14px;
        --maxFontSize: 18px;
        --scaler: 3vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        font-weight: 600;
        position: relative;
        line-height: clamp(20px,3vw, 28px);
        margin-bottom: 0;
       
      }
    }

    &--overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      transform: scale(0, 0);
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: 0.5s ease;
      background-color: $c-white;
      border-radius: 8px;
     
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 16px;
        text-align: center;
        @media (max-width: 991px) {
          padding: 12px;
        }
        img {
          height: 60px;
          transition: transform ease-in .5s;
        }
  
        p {
          color: $c-main;
          font-size: clamp(13px, 3vw, 16px);
          margin-bottom: 0;
          text-align: center;
        }
    }
    &:hover &--overlay {
      transform: scale(1, 1);
      opacity: 1;
      border: 1.5px solid $c-second;

      img {
        transform: rotate(360deg);
        transition: transform ease-in .5s;
      }
    }

  }
}

.card__formation {
  
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
  margin-top: 16px;

  @media (max-width:1199px) {
    grid-template-columns: repeat(auto-fit, minmax(46%, 1fr));
  }

  @media (max-width:600px) {
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  }
  &--item {
    position: relative;
    cursor: pointer;
    transition: 0.2s;
    box-shadow: 0 0px 10px rgba(247, 247, 247, 0.8);
    border-radius: 8px;
    display: flex;
    background-color: $c-white;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    padding: 8px;

  
      text-align: center;
  
      &--icon {
        img,
        svg {
          height: 90px;
          @media (max-width: 1191px) {
            height: 70px;
          }
        }
      }
  
      h4 {
        color: $c-main;
        --minFontSize: 14px;
        --maxFontSize: 16px;
        --scaler: 3vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        font-weight: 600;
        position: relative;
        line-height: clamp(20px,3vw, 28px);
        margin-bottom: 0;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
       
      }

   
    &:hover  {
      border: 1.5px solid $c-second;
      img {
        transform: rotate(360deg);
        transition: transform ease-in .5s;
      }
    }

  }
}


