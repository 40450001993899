.shape--hero--right {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 38%;
    @media (max-width: 800px) {
        width: 80%;
    }
}

.shape--hero--left {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: auto;
    @media (max-width: 800px) {
        width: 30%;
        display: none;
    }
}

.shape--rh--left {
    position: absolute;
    left: 0;
    top: 25%;
    z-index: -1;
    width: auto;
    @media (max-width: 800px) {
        width: 70%;
    }
}