.features--area {
  .item {
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.3s ease-in-out;

    span {
      font-size: clamp(14px, 3vw, 18px);
      font-weight: 500;
      color: $c-fifth;
      text-transform: uppercase;
      text-align: center;
    }

    object {
      height: 70px;
      width: 100%;
      transition: transform 1s ease-in-out;
    }

    // &:hover {
    //   background-color: $c-white;
    //   -webkit-transition: background-color 1s ease;
    //   -moz-transition: background-color 1s ease;
    //   -o-transition: background-color 1s ease;
    //   transition: background-color 1s ease;
    //   -ms-transition: background-color 1s ease;
    //   object {
    //     transform: rotate(360deg);
    //     -webkit-transition: transform 1s ease;
    //     -moz-transition: transform 1s ease;
    //     -o-transition: transform 1s ease;
    //     transition: transform 1s ease;
    //     -ms-transition: transform 1s ease;
    //   }
    //   span {
    //     color: rgba($c-main, 1);
    //   }
    // }

    &:hover {
      background-color: rgba($c-white, 1);
      -webkit-transition: background-color 1s ease;
      -moz-transition: background-color 1s ease;
      -o-transition: background-color 1s ease;
      transition: background-color 1s ease;
      -ms-transition: background-color 1s ease;
      border-radius: 16px;
      object {
        transform: rotate(-20deg);
        -webkit-transition: transform 1s ease;
        -moz-transition: transform 1s ease;
        -o-transition: transform 1s ease;
        transition: transform 1s ease;
        -ms-transition: transform 1s ease;
      }
      span {
        color: $c-main;
      }
    }
  }
}

.advantage--area {
  .gird {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    grid-gap: 16px;
  }

  .item {
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: rgba($c-second, 0.04);
    border-radius: 16px;
    transition: all 0.3s ease-in-out;

    span {
      font-size: clamp(16px, 3vw, 20px);
      font-weight: 500;
      color: $c-forth;
      text-transform: uppercase;
      padding: 16px 0;
    }

    object {
      height: 90px;
      width: 100%;
    }

    &:hover {
      background-color: $c-white;
      -webkit-transition: background-color 1s ease;
      -moz-transition: background-color 1s ease;
      -o-transition: background-color 1s ease;
      transition: background-color 1s ease;
      -ms-transition: background-color 1s ease;
      border-radius: 16px;
      object {
        transform: rotate(360deg);
        -webkit-transition: transform 1s ease;
        -moz-transition: transform 1s ease;
        -o-transition: transform 1s ease;
        transition: transform 1s ease;
        -ms-transition: transform 1s ease;
      }
      span {
        color: rgba($c-main, 1);
      }
    }
  }
}
