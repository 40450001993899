// @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://use.typekit.net/rvz3jgb.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// @font-face {
//   font-family: "FilsonPro";
//   src: url("/assets/fonts/filsonpro/filsonprobook-webfont.woff");
//   src: url("/assets/fonts/filsonpro/filsonprobook-webfont.woff") format("woff"),
//   url("/assets/fonts/filsonpro/filsonprobook-webfont.woff") format("opentype"),
// }
// @font-face {
//   font-family: "FilsonPro-Bold-Italic";
//   src: url("/assets/fonts/filsonpro/filsonprobolditalic-webfont.woff");
//   src: url("/assets/fonts/filsonpro/filsonprobolditalic-webfont.woff") format("woff"),
//   url("/assets/fonts/filsonpro/filsonprobolditalic-webfont.woff") format("opentype"),
// }


@font-face {
  font-family: HelloParisLight;
  src: url('/assets/fonts/Hello_Paris_Sans_Light.ttf');
}

@font-face {
  font-family: HelloParisRegular;
  src: url('/assets/fonts/Hello_Paris_Sans_Regular.ttf');
}
@font-face {
  font-family: HelloParisBold;
  src: url('/assets/fonts/Hello_Paris_Sans_Bold.ttf');
}
@font-face {
  font-family: Mmtsdr;
  src: url('/assets/fonts/Mmtsdr_bold_Regular.ttf');
}


@import '../components/header';
// @import url('https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css');

:root {

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
}

a {
  text-decoration: none;
  color: $c-main;
}

a:hover {
text-decoration: none;
}

::-moz-selection { 
  background-color: rgba($c-second, .7);
  color: white;
}
::selection { 
  background-color: rgba($c-second, .7);
  color: white;; }

figure {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 10px;
	margin-inline-end: 0;
}

body {
  /* overflow: hidden; */
  font-size: 16px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  background-color: $c-background;
  color: $c-dark;
  // overflow-x: hidden ;
}



.height100 {
  height: 100% !important;
}