.tabs {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    font-weight: 300;
    font-size: 1.25em;

    nav {
        text-align: center;
    }

    nav ul {
        position: relative;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: flex;
        margin: 0 auto;
        padding: 0;
        max-width: 900px;
        border-bottom: 1px solid rgba($c-gray-d2, .5);
        list-style: none;
        -ms-box-orient: horizontal;
        -ms-box-pack: center;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
    }

    nav ul li {
        position: relative;
        z-index: 1;
        display: block;
        margin: 0;
        text-align: center;
        -webkit-flex: 1;
        -moz-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    nav a {
        position: relative;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 2.5;
    }

    nav a span {
        vertical-align: middle;
        font-size: 0.75em;
    }

    nav li.tab-current a {
        color: #74777b;
    }

    nav a:focus {
        outline: none;
    }
}


/* Content */
.content-wrap {
    position: relative;

    section {
        display: none;
        margin: 0 auto;
        width: 45%;

        @media (max-width: 991px) {
            width: 90%;
        }
    }
}

.content-wrap section.content-current {
    display: block;
}



/* Fallback */
.no-js .content-wrap section {
    display: block;
    padding-bottom: 2em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.no-flexbox nav ul {
    display: block;
}

.no-flexbox nav ul li {
    min-width: 15%;
    display: inline-block;
}

@media screen and (max-width: 58em) {
    .tabs nav span {
        display: none;
    }

    .tabs nav a:before {
        margin-right: 0;
    }
}

/** tabstyles.scss */



/*****************************/
/* Underline */
/*****************************/

.tabs-style-underline nav {
    background: transparent;
}

.tabs-style-underline nav a {
    padding: 0.25em 0 0.5em;
    // border-left: 1px solid #e7ecea;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $c-white;
    font-weight: 400;
}

.tabs-style-underline nav li:last-child a {
    // border-right: 1px solid #e7ecea;
}

.tabs-style-underline nav li a::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 10px;
    height: 4px;
    background: $c-white;
    content: '';
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transform: translate3d(0, 150%, 0);
    transform: translate3d(0, 150%, 0);
}

.tabs-style-underline nav li.tab-current a::after {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.tabs-style-underline nav a span {
    font-weight: 400;
    color: $c-white;

}