.subscribe--section--area {


    .form--hero--two {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 3%;
        width: 90%;
        @media (max-width: 991px) {
            width: 100%;
          }
        input {
          font-weight: 600;
          height: 75px;
          padding-left: 48px;
          background: none;
          background-color: $c-white;
          border: 1px solid $c-gray-d2;
          border-radius: 8px;
          outline: none;
          width: 100%;
          font-size: 18px;
          @media (max-width: 500px) {
            padding-left: 40px;
          }
          &::placeholder {
            font-weight: 300;
            font-size: 18px;
            @media (max-width: 500px) {
              font-size: 14px;
            }
          }
        }
  
        .icon {
          bottom: 0;
          top: 0;
          color: #515c6f;
          left: 16px;
          line-height: 56px;
          margin: auto;
          opacity: .5;
          position: absolute;
          display: flex;
          align-items: center;
          font-size: 24px;
          @media (max-width: 500px) {
            font-size: 16px;
          }
        }
  
        .button {
          bottom: 0;
          height: 50px;
          margin: auto;
          padding: 0 16px;
          position: absolute !important;
          right: 16px;
          text-transform: capitalize;
          top: 0;
          border-radius: 6px;
          @media (max-width: 500px) {
            padding: 0 8px;
  
            a {
              font-size: 12px;
            }
          }
        }
  
          a {
            padding: 0;
          }
        }

    .image--ill {
        width: 60%;

        @media (max-width: 991px) {
            padding: 32px;
            height: 200px;
            display: none;
        }
    }


}





.error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

#sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    margin-top: 8px;
    font-weight: 600;
}

#sentMessage2,
#sentMessage3,
#sentMessage4,
#sentMessage {
    p {
        color: #fff;
        background: #18d26e;
        text-align: center;
        padding: 10px;
        border-radius: 6px;
        margin-top: 8px;
        font-weight: 600;
    }
}

.loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

// Loading
.no-js #loader {
    display: none;
}

.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

.se-pre-con {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 6%;

        @media (max-width: 800px) {
            width: 16%;
        }
    }
}