
.prestations__area {
  border-radius: 24px;
  background-color: $c-main-dark;
  padding: 24px;
  @media (max-width: 600px) {
    padding: 0;
    padding-bottom: 24px;
  }
  .row {
    padding: 0;
    @media (max-width: 600px) {
      padding: 16px;
    }
  }
  

  .item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 16px;

    @media (max-width: 640px) {
      margin: 0 16px;
    }
    img {
      height: 380px;
      width: 100%;
      object-fit: cover;
      transition: transform 1s ease-in-out;
      border-radius: 16px;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(
        to bottom,
        rgba($c-second, 0) ,
        rgba($c-main, 1) 
      );
      width: 100%;
      height: 100%;
      border-radius: 16px;

      .content {
        padding: clamp(16px, 3vw, 24px);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;

        .head {
          padding-bottom: 16px;
          object {
            height: 24px;
            margin-bottom: 4px;
          }
          h3 {
            color: $c-white;
            font-size: clamp(20px, 3vw, 32px);
            font-weight: 500;
          }
        }
        .short {
          display: none;
          padding-bottom: 16px;
          p {
            margin-bottom: 0;
          }
        }

        .action {
          display: none;
          a {
            text-decoration: none;
            span {
              color: $c-second;
              font-size: clamp(16px, 3vw, 20px);
              padding-right: 8px;
            }
          }
        }
      }
    }

    &:hover {
      // background-color: rgba($c-white, 1);
      // -webkit-transition: background-color 1s ease;
      // -moz-transition: background-color 1s ease;
      // -o-transition: background-color 1s ease;
      // transition: background-color 1s ease;
      // -ms-transition: background-color 1s ease;
      // border-radius: 16px;

      .overlay {
        background-image: linear-gradient(
          to bottom,
          rgba($c-main, .7) ,
          rgba($c-main, 1) 
        );
        -webkit-transition: background-image 1s linear;
        -moz-transition: background-image 1s linear;
        -o-transition: background-image 1s linear;
        transition: background-image 1s linear;
        -ms-transition: background-image 1s linear;
        .content {
          .short,
          .action {
            display: block;
            -webkit-transition: display 1s linear;
            -moz-transition: display 1s linear;
            -o-transition: display 1s linear;
            transition: display 1s linear;
            -ms-transition: display 1s linear;
          }
        }
      }

      img {
        transform: rotate(-10deg) scale(1.4);
        -webkit-transition: transform 1s ease;
        -moz-transition: transform 1s ease;
        -o-transition: transform 1s ease;
        transition: transform 1s ease;
        -ms-transition: transform 1s ease;
      }
      span {
        color: $c-main;
      }
    }
  }

  .item--price {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 640px) {
      margin: 0 16px;
    }
    img {
      height: 360px;
      width: 100%;
      object-fit: cover;
      transition: transform 1s ease-in-out;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(
        to top,
        rgba($c-black, 0.9) 40%,
        rgba($c-black, 0)
      );
      width: 100%;
      height: 100%;

      .content {
        padding: clamp(16px, 3vw, 24px);
        position: absolute;
        bottom: 0;
        left: 0;

        .head {
          padding-bottom: 16px;
          object {
            height: 24px;
            margin-bottom: 4px;
          }
          h3 {
            color: $c-white;
            font-size: clamp(20px, 3vw, 32px);
            font-weight: 500;
          }
        }
        .short {
          padding-bottom: 0;
          p {
            margin-bottom: 0;
          }
        }

        svg {
          margin: 24px 0
        }
        .price {
              span {
                color: $c-second;
              font-size: clamp(18px, 3vw, 24px);
              padding-right: 8px;
              font-weight: 600;
              }
        }
      }
    }

    &:hover {

      .overlay {
        background-image: linear-gradient(
          to top,
          rgba($c-black, 1),
          rgba($c-forth, 0.4)
        );
        -webkit-transition: background-image 1s linear;
        -moz-transition: background-image 1s linear;
        -o-transition: background-image 1s linear;
        transition: background-image 1s linear;
        -ms-transition: background-image 1s linear;
        .content {
          .price {
            color: $c-second;
          }
        }
      }

      img {
        transform: rotate(-10deg) scale(1.4);
        -webkit-transition: transform 1s ease;
        -moz-transition: transform 1s ease;
        -o-transition: transform 1s ease;
        transition: transform 1s ease;
        -ms-transition: transform 1s ease;
      }
      span {
        color: $c-main;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-gap: 16px;

    @media (max-width: 1199px) {
      grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    }
  }
}

.single__prestation {
  &--images {
    // padding: 8px;
    img {
      min-height: 400px;
      box-shadow: rgba(227, 222, 222, 0.16) 0px 1px 4px;
      object-fit: cover;
      width: 100%;
      border-radius: 8px;

      @media (max-width: 991px) {
        height: 350px;
      }
    }
  }

  &--content {
    @media (max-width: 767px) {
      padding-top: 24px;
    }
    &--head {
      margin-top: 24px;
      h1 {
        font-size: clamp(18px, 3vw, 24px);
        font-weight: 700;
        color: $c-main;
        text-align: left;
      }
    }

    &--widget {
      padding: 16px 0;
      align-items: center;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }

    &--short {
      font-size: 16px;
      line-height: 28px;
      color: $c-dark;
      font-weight: 400;
    }

    &--actions {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }
  }

  &--nextPrev {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: rgba($c-main, .04);
    // border-radius: 16px;
    // padding: 16px;
    margin: 32px 0;

    .item {
      background: rgba($c-second, .05);
      padding: 8px 16px;
      border-radius: 6px;
      width: 42%;
      text-align: center;
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 800px) {
        width: 49%;
        height: auto;
      }
      text-decoration: none;

       
        color: $c-second !important;
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 400;

      p {
        margin-bottom: 0;
      }

      
    }
    .txt--right {
      text-align: right;
    }
  
  }

  .actions-btns {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 40px;

    .button {
      @media (max-width: 991px) {
        flex-grow: 1;
      }
      height: 56px;
    }
  }
}


.sticky-top {
  top: 5rem;
  z-index: 0;

  @media (max-width: 991px) {
      position: static;
  }
}

.bg--white {
  background-color: transparent !important;
  padding: 0;
}



.formations__area {

  margin: 60px 0;
  position: relative;
  padding: 32px 0 0 24px;

  @media (max-width:420px) {
    padding: 24px 0 24px 8px;
  }


  .shape {
    background-color: rgba($c-second, .05);
    width: 50%;
    height: 540px;
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
    @media (max-width:420px) {
      width: 60%;
      height: 100%;
    }
  }

  .title {
    color: $c-second;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 600;
  }

  .action {
    width: 30%;
    margin: 24px auto 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width:991px) {
       width: 40%;
    }
    @media (max-width:767px) {
      width: 60%;
    }
    @media (max-width:500px) {
      width: 80%;
    }
  }
}

