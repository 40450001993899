
.footer--section--area {

  background-color: $c-main-dark;
  border-radius: 24px;
  // border: 2px solid rgba($c-second, 1);
  padding:  24px;
  position: relative;

  &::after {
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -2px;
    background: linear-gradient(to left, $c-gray, $c-gray);
    content: '';
    z-index: -1;
    border-radius: 26px;
}

 
@media (max-width: 676px) {
  padding:  16px;

}
  .content {
    text-align: left;

    .logo--footer {
      margin-bottom: 16px;
      height: 80px;
      width: max-content;

      @media (max-width: 676px) {
        width: 100%;
      }
    }


    .short {
      color: $c-white;
      font-weight: 200;
      font-size: clamp(16px, 3vw, 20px);
      margin-bottom: 32px;
      line-height:clamp(24px, 3vw, 28px);

    
    }

    .contact--info {
      .item {
        display: flex;
        align-items: center;

        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          margin-right: 16px;
          // background: #353745;
          border-radius: 8px;
          height: 50px;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 36px;
          }
        }

        .value {
          display: flex;
          flex-direction: column;

          a {
            color: $c-white;
            font-size: clamp(14px, 3vw, 16px);
            margin-bottom: 4px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .social {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      flex-wrap: wrap;
      
      a {
        height: 40px;
        width: 40px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $c-main;
        border-radius: 4px;
        transition: transform 1s ease-in-out;

        i {
          color: $c-white;
        }

        span {
          font-size: 16px;
          color: $c-white;
          transition: transform 1s ease-in-out;
          margin-left: 8px;
        }

        &:hover {
          background-color: $c-main;
          transition: transform 1s ease-in-out;

          i {
            transform: scale(1.2);
          }
        }
      }

      .facebook { background-color: #1877F2 !important; }
      .linkedin { background-color: #3053f1 !important; }
      .instagram { background-color: #d940e4 !important;}

      @media (max-width: 500px) {
      }
    }

    .address--item {
      p {
        color: $c-white;
      }

      .contact--info {
        padding-bottom: 16px;
      }
    }


  }

  .head {
    h3 {
      color: $c-white;
      font-size: clamp(16px, 3vw, 24px);
      font-weight: 600;
      position: relative;
      &::after {
        content: "";
        background-color: $c-second;
        width: 30px;
        height: 4px;
        border-radius: 10px;
        position: absolute;
        left: 0;
        bottom: -10px;
      }

    }
  }

  .map--swiper {
    padding-top: 16px;
    iframe {
      border-radius: 16px;
    }
  }
  
}

.copyright {
  text-align: center;
  padding: 24px 0;

  p {
    text-align: center;
    font-size: clamp(14px, 3vw, 16px);
    color: $c-main;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;

    img {
      height: 16px;
    }
  }

  small {
    color: $c-gray;
    
    a {
      color: rgb(255, 200, 0);
    }

    a:last-child {
      color: #f0b670;

    }
  }
}



