.partners__area {

  margin-bottom: 60px;

  @media (max-width:991px) {
    margin-bottom: 32px;
  }

  &--item {
    background-color: $c-white;
    border-radius: 8px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    transition: border ease-in .4s;
    height: 80px;

    img {
      height: 50px;
      width: 150px;
      opacity: .9;
      transition: opacity ease-in .4s;
    }

    &:hover img {
      opacity: 1;
      transition: opacity ease-in .4s;
    }

    &:hover {
      border: 1px solid rgba($c-second, .1);
      transition: border ease-in .4s;
    }
  }

}