.booking__area {
  .--head {
    width: 100%;
    h4 {
      font-size: 24px;
      font-weight: 500;
      color: $c-black;
    }
    padding-bottom: 16px;
  }
  &--inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba($c-white, 1);
    padding: 24px;
    border-radius: 8px;
    box-shadow: rgba(183, 188, 193, 0.2) 0px 8px 24px;


    @media (max-width: 991px) {
      padding: 16px;
    }

  }
  &--infos {
    background-color: rgba($c-white, 1);
    padding: 24px;
    border-radius: 8px;
    box-shadow: rgba(183, 188, 193, 0.2) 0px 8px 24px;

    @media (max-width: 991px) {
      padding: 16px;
    }
    @media (max-width:767px) {
      margin-top: 16px;
    }


      .top {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-bottom: 16px;
        flex-direction: column;
       
      }
      .image {
        width: 100%;
        margin-bottom: 16px;
        img {
          width:  100%;
          height: 200px;
          object-fit: cover;
          object-position: center;
          border-radius: 6px;
        }
      }

      h5 {
        font-size: clamp(16px, 3vw, 20px);
        font-weight: 500;
        letter-spacing: .2rem;
        color: $c-main-dark;
        margin-bottom: 0;
        margin-top: 16px;
        font-family: Mmtsdr;
        
      }

      .footer {
        border-top: 1px solid rgba($c-gray-f4, 1);
        border-bottom: 1px solid rgba($c-gray-f4, 1);

        padding: 24px 0;
        .subtotal {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $c-dark;

          p {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            color: $c-dark;

            span {
              padding-right: 8px;
              font-weight: 600;
              font-size: 16px;
            }
          }
        }

        .total {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 24px;
          color: $c-dark;

          padding-top: 24px;

          span {
            font-weight: 600;
          }


          #resultProduct,.val {
            color: $c-second;
            font-weight: 700;
          }

        }
      }
  }


  
  .totals--head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $c-white;
    padding: 24px;
    margin: 24px 0;
    border-radius: 8px;

    h3 {
      margin-bottom: 0;
      color: $c-second;
      font-size: 24px;
      font-weight: 700;
    }
    h4 {
      margin-bottom: 0;
      color: $c-third;
      font-size: 18px;
      font-weight: 600;
    }
  }


  .btn-cart-totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    @media (max-width: 600px) {
      justify-content: center;
    }
  }


  .empty--cart {

    h3 {
      font-size: clamp(16px, 3vw, 24px);
      font-weight: 600;
      color: $c-third;
    }

    .box {
      margin: 24px 0;
      border: 1px solid rgba($c-gray, .2);
      border-radius: 8px;
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      i {
        color: rgba($c-gray, .4);
        margin-bottom: 24px;
      }

      h5 {
        color: $c-third;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 24px;
        
      }

    }

  }
 
}

.quote__area {
  margin-bottom: 40px;
  margin-top: 20px;
  .title {
    h4 {
      font-size: 24px;
      font-weight: 600;
      color: $c-main;
    }
    padding-bottom: 16px;
    p {
      font-size: 16px;
      color: $c-gray;
    }
  }
  .--head {
    width: 100%;
    h4 {
      font-size: 24px;
      font-weight: 600;
      color: $c-second;
    }
    padding-bottom: 16px;
  }
  &--inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: $c-white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba($c-second, .6);

  }
  &--infos {
    background-color: $c-white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba($c-second, .6);
    margin-bottom: 16px;

    @media (max-width:767px) {
      margin-top: 16px;
    }

    &--cart {

      .top {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 16px;
      }
      .image {
        margin-right: 16px;
        img {
          width:  150px;
          border-radius: 8px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
        color: $c-second;
        
      }

      .select--prestations {
        
      }

      .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding-bottom: 30px;
        user-select: none;
        & > * {
          margin: 0.5rem 0.5rem;
          @media (max-width: 991px) {
            margin: 0.2rem 0.2rem;
          }
        }
   
        .checkbox-group-legend {
          font-size: 1.5rem;
          font-weight: 700;
          color: #9c9c9c;
          text-align: center;
          line-height: 1.125;
          margin-bottom: 1.25rem;
        }
        .checkbox-input {
          // Code to hide the input
          clip: rect(0 0 0 0);
          clip-path: inset(100%);
          height: 1px;
          overflow: hidden;
          position: absolute;
          white-space: nowrap;
          width: 1px;
          &:checked + .checkbox-tile {
            border-color: $c-main;
            box-shadow: 0 5px 10px rgba(#000, 0.1);
            color: $c-main;
            &:before {
              transform: scale(1);
              opacity: 1;
              background-color: $c-main;
              border-color: $c-main;
            }
            .checkbox-icon,
            .checkbox-label {
              color: $c-main;
            }
          }
          &:focus + .checkbox-tile {
            border-color: $c-main;
            box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;
            &:before {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
        .checkbox-tile {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 180px;
          padding: 16px 8px;
          
          border-radius: 0.5rem;
          border: 2px solid #dde2f2;
          background-color: #fff;
          box-shadow: 0 5px 10px rgba(#000, 0.1);
          transition: 0.15s ease;
          cursor: pointer;
          position: relative;

          @media (max-width: 991px) {
            width: 170px;
          }

          &:before {
            content: "";
            position: absolute;
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            border: 2px solid #b5bfd9;
            background-color: #fff;
            border-radius: 50%;
            top: 0.25rem;
            left: 0.25rem;
            opacity: 0;
            transform: scale(0);
            transition: 0.25s ease;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
            background-size: 12px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
          &:hover {
            border-color: $c-main;
            &:before {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
        .checkbox-icon {
          transition: 0.375s ease;
          img {
            width: 60px;
            height: 60px;
            border-radius: 4px;
            object-fit: cover;
          }
        }
        .checkbox-label {
          padding-top: 16px;
          color: $c-second;
          transition: 0.375s ease;
          text-align: center;
          @media (max-width: 991px) {
            font-size: 14px;
          }
        }

      }
    }
  }
 
}