.bg--pattern {
  background-image: url("/assets/images/shapes/shape-7.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}



.t--paragraph {
  --minFontSize: 12px;
  --maxFontSize: 16px;
  --scaler: 7vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 400;
  line-height: 30px;
  color: #333;
  padding-bottom: 8px;
}

.gray--title {
  --minFontSize: 16px;
  --maxFontSize: 32px;
  --scaler: 7vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 900;
  color: rgb(213, 213, 213);
  padding-bottom: 8px;
}

.green--title {
  --minFontSize: 16px;
  --maxFontSize: 32px;
  --scaler: 7vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 900;
  color: $c-second;
  padding-bottom: 8px;
}

.section--100 {
  padding: 100px 0;
}
.section--80 {
  padding: 80px 0;
}
.section--60 {
  padding: 60px 0;
}
.section--40 {
  padding: 40px 0;
}

.section--20 {
  padding: 20px 0;
}




