.about__area {

  .quote--area {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    flex-wrap: wrap;
    
    @media (max-width: 991px) {
      gap: 8px
    }

    .dots {
      position: relative;
      
      width: 50px;
      height: 0.5rem;
      border: none;
      outline: none;
      background: none;
      transform: rotate(0deg);
      transition: 0.35s ease-in-out;
      span {
        display: flex;
        position: absolute;
        width: 8px;
        height: 8px;
        top: 0;
        border: none;
        outline: none;
        opacity: 1;
        border-radius: 50px;
        background: $c-second;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
            left:  0;
        }
        &:nth-child(2){
            left: 1rem;

        }
        &:nth-child(3) {
            left: 2rem;
        }
       
    }
    }

    p {
      margin-bottom: 0;
      margin-left: 8px;
      font-size: clamp(16px, 3vw, 20px);
      color: $c-main;
       
      @media (max-width: 991px) {
        margin-left: 0;
        text-align: center;
      }
    }
  }


  .min--row{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;

    .box {
      width: 48%;

      h4 {
        color: $c-second;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 16px;
        position: relative;

        &::after {
          content: "";
          background-color: rgba($c-second, 1) ;
          width: 20px;
          height: 4px;
          border-radius: 16px;
          position: absolute;
          left: 0;
          bottom: -10px;
          z-index: -1;
        }
      }
    }

  }

  
  @keyframes slidedown {
    0% {
      opacity: 50;
    }
    
    5% {
      opacity: 0;
      transform: translateY(0.5em);
    }
    
    7% {
      transform: translateY(-1em);
    }
    
    10% {
      opacity: 0;
    }
    
    20% {
      transform: translateY(0);
      opacity: 100;
    }
  }
}

.about__area__founder {
  margin: 60px 0;
  position: relative;
  padding: 32px 0 0 24px;


  .shape {
    background-color: rgba($c-second, .05);
    width: 64%;
    height: 660px;
    border-radius: 16px;
    position: absolute;
    left: 0;
    top: 0;
  }

  .title {
    color: $c-second;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 600;
  }

}

.about__area__history {
  position: relative;
  padding: 32px 0 0 0;


  .shape {
    background-color: rgba($c-main, .05);
    width: 68%;
    height: 570px;
    border-radius: 16px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .title {
    color: $c-second;
    font-size: clamp(18px, 3vw, 24px);
    font-weight: 600;
  }
}


.dynamic {

  
  .dynamic-title {
    float: none;
    margin: 0 auto;
    display: table;
    width: auto;
    line-height: 38px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .margin0 {
    margin: 0 !important;
  }
  span.gold-title {
      font-size: clamp(24px, 3vw, 32px) !important;
      color: $c-second;
  }
  
  .cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
  }
  .cd-words-wrapper .b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
    font-style: normal;
  }
  
  .cd-words-wrapper .b.is-visible {
    position: relative;
  }
  
  .cd-headline.clip span {
    display: inline-block;
    padding: .2em 0;
  }
  .cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
  }
  .cd-headline.clip .cd-words-wrapper::after {
    /* line */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #aebcb9;
  }
  .cd-headline.clip .b {
    opacity: 0;
  }
  .cd-headline.clip .b.is-visible {
    opacity: 1;
  }

}



.discover__area {
  background-image: linear-gradient(to top, rgba($c-main-dark, .6), rgba($c-main, .6) ), url('/assets/images/banner--001.jpg');
  background-position: center bottom;
  background-size: cover;
  padding: 64px;
  border-radius: 24px;
  margin-top: 90px;
  @media (max-width:991px) {
    margin-top: 60px;
    padding: 24px;
  }
  p {
    color: $c-white;
  }
}



.image--box {
  // padding: 8px;
  position: relative;
  z-index: 0;
  text-align: end;

  img {
    height: 100%;
    width: 96%;
    margin-top: 4%;
    border-radius: 16px;
  }


  &::before {
    content: "";
    background-color: rgba($c-second, .1) ;
    width: 96%;
    height: 96%;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
  }

}

.image--box--right {
  // padding: 8px;
  position: relative;
  z-index: 0;
  text-align: start;

  img {
    height: 100%;
    width: 96%;
    margin-top: 4%;
    border-radius: 16px;
  }


  &::before {
    content: "";
    background-color: rgba($c-second, .1) ;
    width: 96%;
    height: 96%;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: -1;
  }

}