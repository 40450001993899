:focus {
  outline: none;
}


.subscribe--input {

  padding: 16px 0;

  label {
    color: $c-gray;
  }

  &--container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      color: $c-gray;
      opacity: .5;
      margin: auto 0;
      line-height: 56px;
    }

    .button {
      position: absolute !important;
      padding: 0 16px;
      right: 8px;
      top: 0;
      bottom: 0;
      line-height: 56px;
      margin: auto;
      text-transform: capitalize;
    }

    input {
      height: 56px;
      padding-left: 40px;
      font-weight: 600;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-weight: 400;
      opacity: .7;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-weight: 400;
      opacity: .7;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      font-weight: 400;
      opacity: .7;
    }
  }

}




.form--area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 16px;
  background-color: $c-white;
  border-radius: 16px;
  padding: clamp(16px, 3vw, 24px);
  border: 1px solid rgba($c-second, .4);
 

  .input--items{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 24px;
    margin-top: 24px;
    @media (max-width: 991px) {
      width: 100%;
      align-items: center;
      padding-left: 0;
    }
  }

  .item--group {
    width: 100%;
    margin-bottom: 16px;

    input,
    select,
    textarea {
      font-size: 16px;
      font-weight: 500;
      color: $c-dark;
      // height: 60px;
      border-radius: 4px;

      background-color: #FCFCFC;
      border: 1px solid #E9F0EF;

      padding: 16px 24px;
      width: 100%;

      &::placeholder {
        font-weight: 400;
      }
    }


    select {

      /* styling */
      display: inline-block;

      /* reset */

      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    select {
      background-image:
        linear-gradient(45deg, transparent 60%, gray 60%),
        linear-gradient(135deg, gray 60%, transparent 60%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 24px) calc(1.4em + 2px),
        calc(100% - 19px) calc(1.4em + 2px),
        calc(100% - 3em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 2.2em;
      background-repeat: no-repeat;
    }

    select:focus {
      background-image:
        linear-gradient(45deg, $c-second 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $c-second 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 19px) 1.4em,
        calc(100% - 24px) 1.4em,
        calc(100% - 3em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 2.2em;
      background-repeat: no-repeat;
      border-color: $c-second;
      outline: 0;
    }



  }
}


.form__area {
  .item--group {
    width: 100%;
    margin-bottom: 16px;
    position: relative;

    input,
    select,
    textarea {
      font-size: 16px;
      font-weight: 500;
      color: $c-fifth;
      // height: 60px;
      border-radius: 4px;

      background-color: $c-light;
      border: 1px solid $c-gray-f4;

      padding: 16px 24px;
      width: 100%;
      padding-left: 48px;

      &::placeholder {
        font-weight: 400;
        color: $c-fifth;
      }

    }

    i {
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      color: $c-second;
      font-size: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    select {

      /* styling */
      display: inline-block;

      /* reset */

      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    select {
      background-image:
        linear-gradient(45deg, transparent 60%, gray 60%),
        linear-gradient(135deg, gray 60%, transparent 60%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 24px) calc(1.4em + 2px),
        calc(100% - 19px) calc(1.4em + 2px),
        calc(100% - 3em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 2.2em;
      background-repeat: no-repeat;
    }

    select:focus {
      background-image:
        linear-gradient(45deg, $c-second 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $c-second 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 19px) 1.4em,
        calc(100% - 24px) 1.4em,
        calc(100% - 3em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 2.2em;
      background-repeat: no-repeat;
      border-color: $c-second;
      outline: 0;
    }



  }
}


.check--area {
  margin: 16px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;

  $transition: 200ms linear;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    width: 100%;
  }

  label.radio-card {
    cursor: pointer;
    width: 33%;

    @media (max-width: 991px) {
      // width: 48%;
    }
    @media (max-width: 389px) {
      // width: 100%;
    }


    .card-content-wrapper {
      background: #fff;
      box-shadow: rgba(176, 183, 189, 0.2) 0px 0px 16px;
      border: 2px solid transparent;

      border-radius: 8px;
      width: 100%;
      height: 200px;

      padding: 16px;
      display: grid;
      transition: $transition;
      @media (max-width: 1199px) {
        height: 230px;
      }

      @media (max-width: 389px) {
        height: 180px;
      }

      .head {
        // display: flex;
        // align-items: flex-start;
        // justify-content: space-between;
        width: 100%;
      }
    }

    .check-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      border: solid 2px #e3e3e3;
      border-radius: 50%;
      transition: $transition;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 12px;
        background-position: center center;
        transform: scale(1.6);
        transition: $transition;
        opacity: 0;
      }
    }

    input[type='radio'] {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      &:checked {
        +.card-content-wrapper {
          // box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px $c-forth;
          border: 2px solid $c-forth;

          .check-icon {
            background: $c-forth;
            border-color: $c-forth;
            transform: scale(1.2);

            &:before {
              transform: scale(1);
              opacity: 1;
            }
          }
        }
      }

      &:focus {
        +.card-content-wrapper {
          .check-icon {
            box-shadow: 0 0 0 4px rgba($c-forth, 0.2);
            border-color: $c-forth ;
          }
        }
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      img {
        margin-bottom: 8px;
        width: 100%;
        height: 100px;
        border-radius: 8px;
      }

      h4 {
        font-size: clamp(14px, 3vw, 16px);
        color: $c-dark;
        font-weight: 600;
        margin-bottom: 10px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: $c-gray-76;
      }
    }
  }
}

