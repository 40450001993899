.banner--section {
  position:relative;
  .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 540px;
    transition: -webkit-transform 2s ease;
    transition: transform 2s ease;
    transition: transform 2s ease, -webkit-transform 2s ease;
    border-radius: 24px;
    margin-top: 16px;

    @media (max-width: 991px) {
      height: 500px;
    }
    @media (max-height: 768px) {
      height: 500px;
    }
  }
  &--caption {
      position: absolute;
      right: 15%;
      left: 15%;
      bottom: 6%;
      // top: 0;
      margin: auto;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.25rem;
      // padding-bottom: 1.25rem;
      // min-width: 560px;
      // max-width: 700px;
      // min-height: 250px;
      // height: fit-content;
      // border-radius: 16px;
      // color: #fff;
      text-align: center;
      animation-duration: 1s;
      animation-delay: 2s;
      // background-color: rgba($c-white, .9);

      @media (max-width: 767px) {
        right: 1%;
        left: 1%;
      }

      img {
        height: 56px;
        opacity: .6;
      }

      &--title {
          --minFontSize: 24px;
          --maxFontSize: 42px;
          --scaler: 5vw;
          font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
          font-weight: 700;
          padding: 8px 0;
          color: $c-white;
          // text-shadow: 2px 2px $c-white;
      }
      &--subtitle {
        --minFontSize: 14px;
        --maxFontSize: 18px;
        --scaler: 5vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        font-weight: 400;
        animation-duration: .5s;
        line-height: clamp(24px, 3vw, 32px);
        color: $c-light;

    }
      &--actions {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 24px 0;

          @media (max-width: 991px) {
            margin: 16px 0;

            .btn--large {
              height: 50px !important;
            }
          }
      }
  }


  // .carousel-indicators {
  //   left: unset;
  //   right: 0;
  //   margin-right: 5%;
  //   button {
  //     background-color: $c-third;
  //     width: 12px;
  //     height: 12px;
  //     border-radius: 50%;
  //   }
  // }


  .carousel-control-next, .carousel-control-prev {
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 16px;
    padding: 0;
    color: #fadec6;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 1;
    transition: opacity .15s ease;

    svg {
      height: 48px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}


.hero__section {
  margin-top: 16px;
  position: relative;
  border-radius: 24px;
  background-color: $c-background;

  &--image {
    height: 560px;
    width: 100%;
    object-fit: cover;
    border-radius: 24px;
    @media (max-width: 991px) {
      height: 460px;
    }
  }

  &--overlay {
    background-image: linear-gradient(to top, rgba($c-main, .9), rgba($c-second, .1));
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 24px;
    z-index: 1;

  }

  &--caption {
    position: absolute;
    right: 10%;
    left: 10%;
    bottom: 6%;
    // top: 0;
    margin: auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.25rem;    
    text-align: center;
    animation-duration: 1s;
    animation-delay: 2s;

    @media (max-width: 767px) {
      // min-width: 400px;
      // height: 180px;
      // padding: .75rem;
      // right: 8%;
      // left: 8%;
      right: 4%;
      left: 4%;
      padding: 0;
    }


    .img {
      height: 28px;
    }
   
    &--title {
        --minFontSize: 24px;
        --maxFontSize: 36px;
        --scaler: 4vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        // font-weight: 700;
        padding: 16px 0 0;
        color: $c-white;
        // text-shadow: 2px 2px $c-white;
        animation-duration: 1s;
        animation-delay: 1.5s;
        font-weight: 700;
        line-height: clamp(40px, 5vw, 56px);
        margin-bottom: 0;
    }
    &--subtitle {
      --minFontSize: 16px;
      --maxFontSize: 20px;
      --scaler: 4vw;
      font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
      font-weight: 400;
      animation-duration: .5s;
      animation-delay: 1.4s;
      color: $c-light;
      line-height: clamp(16px, 5vw, 32px);
      margin-bottom: 0;

  }
    &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
        animation-duration: 1s;
        animation-delay: 2.2s;
        margin: 16px 0 8px;
    }
}

}








