//Sizing Variables
$grid-breakpoints: (
  xs: 0,
  ss: 380px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

//Colours
$c-main: #143F6B;
$c-main-dark: #133150;
$c-main2: #0b3866;
$c-second: #F55353;
$c-second-light: #EFDDD0;
$c-third: #ff8585;
$c-forth: #eeaaaa;
$c-fifth: #4D5686;
$c-dark: #231F20;
$c-background: #FCFCFC;
$c-background2: #F7FCFB;
$c-black: #211411;
$c-light: #FDFEFF;
$c-white: #ffffff;
$c-gray: #8498AF;
$c-gray-76: #425576;
$c-gray-d2: #CAD1DE;
$c-gray-light: #EBEFF5;
$c-gray-9c: #83839c;
$c-gray-l2: #F8F8FF;
$c-gray-ff: #E9F0EF;
$c-gray-f4: #E7EAF4;
$c-blue-dark: #002E74;
$c-dark-2: #0D285A;


$c-grey-33: #333333;
$c-grey-f7: #f7f7f7;
$c-grey-ab: #ababab;
$c-grey-b9: #b9b9b9;



