.contact--infos {
  background-color: #FCFCFC;
  border: 1px solid $c-gray-ff;
  padding: 24px;
  border-radius: 8px;

  @media (max-width: 1199px) {
    padding: 16px;
  }

  @media (max-width: 991px) {
    margin-bottom: 24px;
  }

  .item {
    border-bottom: 1px solid $c-gray-ff;
    padding-bottom: 16px;
    padding-top: 16px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;

    }

    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-right: 16px;
      @media (max-width: 1199px) {
        height: 40px;
      }
      
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span {
        padding-bottom: 8px;
        color: $c-main;
        font-weight: 300;
        font-size: 16px;
      }

      a {
        color: $c-main-dark;
        font-weight: 500;
        font-size: 16px;
        text-decoration: none;
      }
    }
  }
}

.faq--category {

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: $c-white;
    border: 1px solid $c-gray-ff;
    border-radius: 8px;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 40px;
        margin-right: 16px;
      }

      h4 {
        font-size: 16px;
        font-weight: 500;
        color: $c-main-dark;
        margin-bottom: 0;
      }
    }

    i {
      color: $c-gray;
    }
  }
}

.faq--content {
  background-color: $c-background2;
  border: 1px solid $c-gray-ff;
  border-radius: 8px;
  padding: 16px;


  .accordion {
    // border: 1px solid $c-gray-ff;
    // padding: 0 10px;
    // margin-bottom: 8px;
    list-style: none outside;
  }

  .accordion-item {
    border: 1px solid $c-gray-ff;
    margin-bottom: 8px;
    padding: 16px 16px;background-color: $c-white;
    border-radius: 8px;

  }

  .accordion-item-hd {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: $c-main-dark;
    font-weight: 500;

    svg {
      margin-right: 16px;
    }
  }

  .accordion-item-input:checked~.accordion-item-bd {
    max-height: 1000px;
    padding-top: 15px;
    margin-bottom: 15px;
    -webkit-transition: max-height 1s ease-in, margin .3s ease-in, padding .3s ease-in;
    transition: max-height 1s ease-in, margin .3s ease-in, padding .3s ease-in;
  }

  .accordion-item-input:checked~.accordion-item-hd>.accordion-item-hd-cta {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .accordion-item-hd-cta {
    display: block;
    width: 30px;
    position: absolute;
    top: calc(50% - 6px);
    /*minus half font-size*/
    right: 0;
    pointer-events: none;
    -webkit-transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    text-align: center;
    font-size: 12px;
    line-height: 1;
  }

  .accordion-item-bd {
    max-height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: max-height .15s ease-out, margin-bottom .3s ease-out, padding .3s ease-out;
    transition: max-height .15s ease-out, margin-bottom .3s ease-out, padding .3s ease-out;
  }

  .accordion-item-input {
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1;
    overflow: hidden;
    position: absolute;
    left: -9999px;
  }
}